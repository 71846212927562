<template>
  <eden-confirm-dialog
    title="Reset Rider Password"
    button-text="Reset"
    button-type="success"
    :show.sync="shouldShow"
    @confirm="reset"
  >
    <p>
      Are you sure you want to reset
      <strong class="text-grey-primary">{{ riderName }}'s </strong> password?
    </p>
  </eden-confirm-dialog>
</template>
  
  <script>
import riders from "@/requests/logistics/index.js";


export default {
  name: "RiderPasswordReset",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rider: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    riderName() {
      return this.formatName(this.rider.firstname + " " + this.rider.lastname);
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    reset() {
      const payload = {
        third_party_user_id: this.rider.id,
        user_type: "rider",
      };
      riders
        .reset(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
  
  <style lang="scss"></style>
  